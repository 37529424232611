import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';

function initializeImageSlider()
{
    const sliders = document.querySelectorAll(".acf-block-image-slider .swiper");

    if (sliders.length > 0) {
        for (let i = 0; i < sliders.length; i++) {
            const swiperEl = sliders[i];
            const nextButton = swiperEl.querySelector('.swiper-button-next');
            const prevButton = swiperEl.querySelector('.swiper-button-prev');
            const pagination = swiperEl.querySelector('.swiper-pagination');
            const swiperParams = {
                modules: [Navigation, Pagination],
                loop: true,
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                pagination: {
                    el: pagination,
                    clickable: true
                },
                slidesPerView: 1,
                spaceBetween: 20,
            };

            new Swiper(swiperEl, swiperParams);
        }
    }
}


export function imageSlider()
{
    // Для редактора
    if ( window.acf ) {
        window.acf.addAction('render_block_preview/type=image-slider', initializeImageSlider);
    }

    // Для фронтенда
    initializeImageSlider();
}
